import React from "react";

export const WarehouseConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
          path: "/materials-management/warehousing/:type/:warehouseId",
           component: React.lazy(() => import("./WarehouseRecord"))
    },
    {
        path: "/materials-management/warehousing",
      component: React.lazy(() => import("./Warehouse"))
    },

  ]
};
