import React from "react";

export const SuppliersConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
    },
    routes: [
        {
            path: "/materials-management/suppliers/:productId",
            component: React.lazy(() => import("./SuppliersRecord"))
        },
        {
            path: "/materials-management/suppliers",
            component: React.lazy(() => import("./Suppliers"))
        },
    ]
};