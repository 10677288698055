import React from "react";

export const CrewsConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
      path: "/maintenance-management/crews",
      component: React.lazy(() => import("./Crews"))
    }
  ]
};
