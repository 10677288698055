import IotManagement from "../main/Icons/iot-management.png";
import IotHealth from "../main/Icons/iot-health.png";
import Locations from "../main/Icons/locations.png";
import Networks from "../main/Icons/network.png";
import Gateways from "../main/Icons/gateway.png";
import Sensors from "../main/Icons/sensor.png";
import ActivityManagement from "../main/Icons/activity-management.png";
import KanbanIcon from "../main/Icons/kanban.png";
import Analytics from "../main/Icons/analytics.png";
import Devices from "../main/Icons/devices.png";
import Maintenance from "../main/Icons/maintenance-analysis-100-white.png";
import MaintenanceManagement from "../main/Icons/maintenance-management-64-white.png";
import Customs from "../main/Icons/customs.png";
import Equipment from "../main/Icons/equipment-analysis-100-white.png";
import AssetManagementIcon from "../main/Icons/asset-management-100-white.png";
import SystemAnalysis from "../main/Icons/system-status-100-white.png";
import StockAnalysis from "../main/Icons/stock-analysis-100-white.png";
import DocumentManagement from "../main/Icons/document-management-100-white.png";
import ClassificationSchemes from "../main/Icons/classification-schemes-100-white.png";
import Crews from "../main/Icons/crews-100-white.png";
import FacilitiesIcon from "../main/Icons/facilities-icon.png";
import AssetsIcon from "../main/Icons/Assets-Icon.png";
import LifeCycleManagementIcon from "../main/Icons/Life-Cycle-Management.png";
import HSSEIcon from "../main/Icons/HSSE-Icon.png";
import RiskManagementIcon from "../main/Icons/Risk-Management-Icon.png";
import ContractorManagementIcon from "../main/Icons/Contractor-Management-Icon.png";
import ChangeManagementIcon from "../main/Icons/change-management.png";
import StrategyManagementIcon from "../main/Icons/strategy-management.png";
import DocumentsManagementIcon from "../main/Icons/documents-management.png";
import ProjectsIcon from "../main/Icons/projects.png";
import TeamCalendarIcon from "../main/Icons/team-calendar.png";
import CollaborationIcon from "../main/Icons/collaboration.png";
import PeopleIcon from "../main/Icons/people.png";
import MaterialsManagement from "../main/Icons/materials-management.png";
import PartsCatalogueIcon from "../main/Icons/parts-catalogue.png";
import StorageLocationsIcon from "../main/Icons/storage-locations.png";
import SuppliesIcon from "../main/Icons/suppliers-icon.png";
import OrderTrackingIcon from "../main/Icons/order-tracking.png";
import PurchaseRequestsIcon from "../main/Icons/purchase-requests.png";
import SettingsIcon from "../main/Icons/settings.png";
import UserCheckIcon from "../main/Icons/person-confirm.png";
import UserKeyIcon from "../main/Icons/person-key.png";
import StaffIcon from "../main/Icons/person-staff.png";
import TaskGroupIcon from "../main/Icons/icons8-task-group-100.png";
import speedometerwhite from "../main/Icons/speedometer-white.png";
import WarehouseIcon from "../main/Icons/warehouse-icon.png";
import WorkRequestIcon from "../main/Icons/work-request-icon-100-white.png";
import WorkflowIcon from "../main/Icons/icons8-workflow-50.png";

const navigationConfig = [
    {
        id: "1",
        title: "Analytics",
        type: "collapse",
        icon: Analytics,
        children: [
            {
                id: "1",
                title: "Devices and Sensors",
                type: "item",
                icon: Devices,
                url: "/analytics/1"
            },
            {
                id: "2",
                title: "Maintenance Analysis",
                type: "item",
                icon: Maintenance,
                url: "/analytics/2"
            },
            {
                id: "3",
                title: "Equipment Analysis",
                type: "item",
                icon: Equipment,
                url: "/analytics/3"
            },
            {
                id: "4",
                title: "Production Analysis",
                type: "item",
                icon: StrategyManagementIcon,
                url: "/analytics/4"
            },
            {
                id: "5",
                title: "Stock Analysis",
                type: "item",
                icon: StockAnalysis,
                url: "/analytics/5"
            },
            {
                id: "6",
                title: "System Use Analysis",
                type: "item",
                icon: SystemAnalysis,
                url: "/analytics/6"
            },
            {
                id: "7",
                title: "Dashboards Settings",
                type: "item",
                icon: IotHealth,
                url: "/analytics/7"
            }
        ]
    },
    {
        id: "2",
        title: "IoT",
        type: "collapse",
        icon: IotManagement,
        children: [
            {
                id: "8",
                title: "Dashboards",
                type: "item",
                icon: speedometerwhite,
                url: "/iot/dashboards/boards"
            },
            {
                id: "10",
                title: "Networks",
                type: "item",
                icon: Networks,
                url: "/IoT/Networks"
            },
            {
                id: "11",
                title: "Gateways",
                type: "item",
                icon: Gateways,
                url: "/IoT/Gateways"
            },
            {
                id: "12",
                title: "Sensors",
                type: "item",
                icon: Sensors,
                url: "/IoT/Sensors"
            },
        ]
    },
    {
        id: "3",
        title: "Asset Management",
        type: "collapse",
        icon: AssetManagementIcon,
        children: [
            {
                id: "51",
                title: "All Assets",
                type: "item",
                icon: AssetsIcon,
                url: "/asset-management/all/assets"
            },
            {
                id: "50",
                title: "Facilities & Locations",
                type: "item",
                icon: FacilitiesIcon,
                url: "/asset-management/locations"
            },
            {
                id: "13",
                title: "Equipment",
                type: "item",
                icon: Equipment,
                url: "/asset-management/assets"
            },
            {
                id: "17",
                title: "Document Management",
                type: "item",
                icon: DocumentManagement,
                url: "/asset-management/document-management"
            },
            {
                id: "21",
                title: "Meters",
                type: "item",
                icon: speedometerwhite,
                url: "/asset-management/meters"
            }
        ]
    },
    {
        id: "4",
        title: "Maintenance Management",
        type: "collapse",
        icon: MaintenanceManagement,
        children: [
            {
                id: "49",
                title: "KPI Dashboard",
                type: "item",
                icon: speedometerwhite,
                url: "/maintenance-management/kpi-dashboard/49"
            },
            {
                id: "54",
                title: "Calendar",
                type: "item",
                icon: TeamCalendarIcon,
                url: "/maintenance-management/calendar"
            },
            {
                id: "22",
                title: "Work Orders",
                type: "item",
                icon: KanbanIcon,
                url: "/maintenance-management/work-order-board/1"
            },
            {
                id: "23",
                title: "Work Requests",
                type: "item",
                icon: WorkRequestIcon,
                url: "/maintenance-management/work-requests"
            },
            {
                id: "24",
                title: "Maintenance Plans",
                type: "item",
                icon: DocumentManagement,
                url: "/maintenance-management/maintenance-plans"
            },
            {
                id: "27",
                title: "Task Groups",
                type: "item",
                icon: TaskGroupIcon,
                url: "/maintenance-management/task-groups"
            },
            {
                id: "26",
                title: "Crews",
                type: "item",
                icon: Crews,
                url: "/maintenance-management/crews"
            }
        ]
    },
    {
        id: "6",
        title: "Materials Management",
        type: "collapse",
        icon: MaterialsManagement,
        children: [
            {
                id: "52",
                title: "Warehouse Dashboard",
                type: "item",
                icon: speedometerwhite,
                url: "/materials-management/warehouse-dashboard/52"
            },
            {
                id: "55",
                title: "Suppliers",
                type: "item",
                icon: PartsCatalogueIcon,
                url: "/materials-management/suppliers"
            },
            {
                id: "35",
                title: "Warehousing",
                type: "item",
                icon: WarehouseIcon,
                url: "/materials-management/warehousing/"
            },
            {
                id: "34",
                title: "Master Parts",
                type: "item",
                icon: PartsCatalogueIcon,
                url: "/materials-management/master-parts"
            }
        ]
    },
    {
        id: "9",
        title: "Settings",
        type: "collapse",
        icon: SettingsIcon,
        children: [
            {
                id: "56",
                title: "Workflows",
                type: "item",
                icon: WorkflowIcon,
                url: "/settings/workflows"
            },
            {
                id: "14",
                title: "Classification Schemes",
                type: "item",
                icon: ClassificationSchemes,
                url: "/settings/classification-schemes"
            },
            {
                id: "25",
                title: "Personnel",
                type: "item",
                icon: StaffIcon,
                url: "/settings/people"
            },
            {
                id: "48",
                title: "User Roles",
                type: "item",
                icon: PeopleIcon,
                url: "/settings/user-roles"
            },
            {
                id: "43",
                title: "Users",
                type: "item",
                icon: PeopleIcon,
                url: "/settings/users"
            }
        ]
    },
];

export default navigationConfig;