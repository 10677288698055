import React from "react";

export const MasterPartConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
          path: "/materials-management/master-parts/:productId",
      component: React.lazy(() => import("./MasterRecord"))
    },
    {
        path: "/materials-management/master-parts",
      component: React.lazy(() => import("./MasterParts"))
    },
  ]
};
