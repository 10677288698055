import React from "react";

export const AllAssetsConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
      path: "/asset-management/all/assets",
      component: React.lazy(() => import("./AllAssets"))
    },

  ]
};
