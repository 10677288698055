import React from "react";

export const WorkRequestsConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
          path: "/maintenance-management/work-requests",
          component: React.lazy(() => import("./WorkRequests"))
    }
  ]
};
