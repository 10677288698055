import React from "react";

export const SensorConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
      path: "/IoT/Sensors",
      component: React.lazy(() => import("./Sensors"))
    },
    {
        path: "/IoT/Sensor/:productId",
        component: React.lazy(() => import("./SensorsRecord"))
    },
  ]
};
