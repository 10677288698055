import React from "react";

export const AssetsConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
  routes: [
    {
      path: "/asset-management/assets",
      component: React.lazy(() => import("./Assets"))
    },
    {
      path: "/asset-management/asset/:productId",
      component: React.lazy(() => import("./AssetRecord"))
    },
  ]
};
