import React, { useState, useEffect } from "react";
import { LicenseInfo } from '@material-ui/x-grid';
import { LicenseManager } from 'ag-grid-enterprise';
import moment from 'moment';
import { Work, WorkTwoTone, AttachFile } from "@material-ui/icons";
import { Alert } from '../components/dialogs/Alerts';
import PriorityLowIcon from '@material-ui/icons/PriorityHigh';
import { green, red } from '@material-ui/core/colors';

import SubtaskNone from '../Icons/icons8-task-100.png';
import SubtaskCompleted from '../Icons/icons8-task-100-2.png';
import SubtaskNoMandatory from '../Icons/icons8-task-100-3.png';
import SubtaskPartial from '../Icons/icons8-task-100-4.png';

import LaborNone from '../Icons/icons8-work-96.png';
import LaborEst from '../Icons/icons8-work-96-4.png';
import LaborComplete from '../Icons/icons8-work-96-2.png';

import PartsNone from '../Icons/icons8-list-of-parts-96-5.png';
import PartsEst from '../Icons/icons8-list-of-parts-96-4.png';
import PartsComplete from '../Icons/icons8-list-of-parts-96-2.png';

import StatusIncomplete from '@material-ui/icons/Error';
import StatusPartial from '../Icons/icons8-warning-96.png';
import StatusComplete from '@material-ui/icons/CheckCircle';

import WarehouseIcon from "../Icons/warehouse-icon.png";
import WarehouseLocationIcon from "../Icons/Assets-Icon.png";

const iconStyle = { color: "White", width: "32px", height: "32px" };

export const warehouseActions = [
    { key: "sl", name: 'Add Warehouse Location', icon: <img src={WarehouseLocationIcon} style={{ height: "32px", width: "32px" }} />},
    { key: "wh", name: 'Add Warehouse', icon: <img src={WarehouseIcon} style={{ height: "32px", width: "32px" }} />}
];

export const defaultColDef = {
    editable: false,
    resizable: true,
    sortable: true,
    filter: 'agTextColumnFilter',
    menuTabs: ['filterMenuTab'],
    filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
        buttons: ['reset', 'apply']
    }
};

export const taskLegendData = [
    {
        category: "subtask",
        title: "Subtask Icons",
        content: [
            {
                key: 1,
                icon: <img className="w-24" src={SubtaskNone} />,
                label: "No Items Specified"
            },
            {
                key: 3,
                icon: <img className="w-24" src={SubtaskPartial} />,
                label: "Items Partially Complete"
            },
            {
                key: 4,
                icon: <img className="w-24" src={SubtaskCompleted} />,
                label: "All Items Complete"
            },
            {
                key: 2,
                icon: <img className="w-24" src={SubtaskNoMandatory} />,
                label: "Mandatory Items Not Complete"
            },
        ]
    },
    {
        category: "labor",
        title: "Labor Icons",
        content: [
            {
                key: 1,
                icon: <img className="w-24" src={LaborNone} />,
                label: "No Labor Added To Task"
            },
            {
                key: 2,
                icon: <img className="w-24" src={LaborEst} />,
                label: "Estimated Labor Added Only"
            },
            {
                key: 3,
                icon: <img className="w-24" src={LaborComplete} />,
                label: "Estimated And Actual Labor Added"
            },
        ]
    },
    {
        category: "parts",
        title: "Parts Icons",
        content: [
            {
                key: 1,
                icon: <img className="w-24 mr-5" src={PartsNone} />,
                label: "No Parts Added To Task"
            },
            {
                key: 2,
                icon: <img className="w-24 mr-5" src={PartsEst} />,
                label: "Estimated Parts Added Only"
            },
            {
                key: 3,
                icon: <img className="w-24 mr-5" src={PartsComplete} />,
                label: "Estimated And Actual Parts Added"
            },
        ]
    },
    {
        category: "status",
        title: "Task Status Icons",
        content: [
            {
                key: false,
                icon: <img className="w-24" src={StatusPartial} />,
                label: "Not Completed"
            },
            {
                key: true,
                icon: <StatusComplete className="w-24" style={{ color: green[500] }} />,
                label: "Completed"
            },
        ]
    },
];

export let signIn = false;

export const networkDescription = "A sensor network is an organization grouping of one or more sensors and gateways.\n" +
    "Sensors communicate through their gateway to your sensor network.Sensors added to a network will be\n" +
    "able to communicate through any of the gateways that are assigned to the same network.";

export const sensorInfoDescription = "Each sensor has a label affixed to it, either on the back or the side of the sensor enclosure." +
"If you do not see the label on the side or the back of the enclosure, open the enclosure and look inside as the label might have been placed there.\r\n" +
    "The Sensor ID is the numeric code and the Sensor Code is the alpha/ alpanumeric code on the label.\n" +
    "Should your enclosure not have a label with this information or you missplaced the ID and Code, please contact our helpdesk at: support@iox-connect.com.";

export function LogIn() {
  signIn = true;
};

export const dateFormatter = {
    valueFormatter: ({ value }) => {
        if (!value) {
            return '';
        }

        return moment.utc(value).local().format('MMMM DD, YYYY HH:mm:ss');
    },
};

export const formattedMediumDate = (value) => {
    if (!value) {
        return '';
    }

    return moment.utc(value).local().format('MMM DD, YYYY HH:mm');
};

export const formattedDate = (value) => {
    if (!value) {
        return '';
    }

    return moment.utc(value).local().format('MMMM DD, YYYY HH:mm:ss');
};

export const mergeDateAndTime = (date, time) => {
    if (!date || !time) {
        return '';
    }

    const converted = moment.utc(date).format('YYYY-MM-DD') + 'T' + time;

    return converted;
};

export function timeConvert(num) {
    num = num * 60;
    var hours = Math.floor(num / 60);
    var minutes = Math.round(num % 60);
    return (hours > 9) ? hours : '0' + hours + ":" + ((minutes > 9) ? minutes : '0' + minutes);
};

export function getHoursMinutes(d) {
    d = Number(d);
    const results = { hours: 0, minutes: 0 };
    results.hours = Math.floor(d / 3600);
    results.minutes = Math.floor(d % 3600 / 60);
    return results;
};

export const priorityFormatter = {
    valueFormatter: ({ value }) => {
        const priority = Priorities.find(p => p.Id === value);

        if (priority) {
            return priority.name;
        }
        else {
            return "N/A";
        }
    },
};

export function setLicenseKey(xKey, agKey) {
    LicenseInfo.setLicenseKey(xKey);
    LicenseManager.setLicenseKey(agKey);
};

export const AcceptedCapitals = "ABCDEFGHJKLMNOPQRSTUVWXYZ";
export const AcceptedLowers = "abcdefghijkmnopqrstuvwxyz";
export const AcceptedDigits = "0123456789";
export const AcceptedSymbols = "!@$?._-#";
export const ProfilePath = "/user/profile";


export const WorkRequestRootFolder = "WorkRequests";
export const WorkOrderRootFolder = "WorkOrders";
export const AssetRootFolder = "Assets";
export const TasksGroupRootFolder = "TaskGroup";
export const GatewaysRootFolder = "Gateways";
export const SensorsRootFolder = "Sensors";

export const Priorities = [
    { Id: 3, name: 'High', colour: 'bg-red-300' },
    { Id: 2, name: 'Medium', colour: 'bg-orange-300' },
    { Id: 1, name: 'Low', colour: 'bg-green-300' }
];

export const WorkSelectionType = [
    { Id: 1, name: 'PM', colour: '' },
    { Id: 2, name: 'FAILURE', colour: '' }
];

export const Rotations = [
    { Id: 1, name: 1, colour: '' },
    { Id: 2, name: 2, colour: '' },
    { Id: 3, name: 3, colour: '' }
];

export const Boolean = [
    { Id: 1, name: true, colour: '' },
    { Id: 2, name: false, colour: '' }
];

export const Result = [
    { Id: 1, name: 'Pass', colour: 'bg-green-300' },
    { Id: 2, name: 'Fail', colour: 'bg-red-300' },
    { Id: 3, name: 'Flag', colour: 'bg-orange-300' }
];

export const Confirm = [
    { Id: 1, name: 'Yes', colour: 'bg-green-300' },
    { Id: 2, name: 'No', colour: 'bg-red-300' }
];

export const WorkStepperIcons = {
    1: <Work />,
    2: <WorkTwoTone />,
    3: <AttachFile />,
};

export function getPriority(priorityId) {
    switch (priorityId) {
        default:
            return <React.Fragment>
                <Alert severity="info">NONE</Alert>
            </React.Fragment>
        case 1:
            return <React.Fragment>
                <Alert icon={<PriorityLowIcon fontSize="inherit" />} severity="success">LOW</Alert>
            </React.Fragment>
        case 2:
            return <React.Fragment>
                <Alert severity="warning">MEDIUM</Alert>
            </React.Fragment>
        case 3:
            return <React.Fragment>
                <Alert severity="error">HIGH</Alert>
            </React.Fragment>
    }
}

export const WorkOrderStatusCodes = [
    '',
    'Open',
    'In Review',
    'Completed',
    'Closed'
];

export function getStatus(statusId) {
    switch (statusId) {
            default:
                return "N/A";
            case 1:
                return "Open";
            case 2:
                return "In Review";
            case 3:
                return "Completed";
            case 4:
                return "Closed";
            case 5:
                return "Cancelled";
        }
    }

export const WorkStepperMenus = [
    { menu: "Work Order Details", index: 0 },
    { menu: "Tasks", index: 1 },
    { menu: "Files", index: 3 },
];

export const PlannedTaskHeadCells = [
    { id: 'plannedTaskName', numeric: false, disablePadding: true, label: 'Task Name' },
    { id: 'fullName', numeric: false, disablePadding: false, label: 'Responsible Person' },
    { id: 'subtasksCount', numeric: true, disablePadding: false, label: '# Subtasks' },
    { id: 'estLaborHrs', numeric: true, disablePadding: false, label: 'Est. Labor Hrs' },
];

export const TaskHeadCells = [
    { id: 'taskDescription', numeric: false, disablePadding: true, label: 'Task Name' },
    { id: 'responsiblePerson', numeric: false, disablePadding: false, label: 'Responsible Person' },
    { id: 'subtasks', numeric: true, disablePadding: false, label: '# Subtasks' },
    { id: 'estimatedTaskDuration', numeric: true, disablePadding: false, label: 'Est. Job Duration' },
];

export const NewTaskDefault = {
    taskDescription: '',
    estDuration: new Date(new Date().setHours(0, 0, 0, 0)),
    //subtasks: 1,
    //subtasksList: [
    //    {
    //        order: 1,
    //        subTaskDescription: 'Additional Notes/Comments',
    //        subTaskTypeId: 1,
    //        subTaskTypeName: 'Short Text',
    //        markAsRequired: true,
    //    }
    //],
};

export const WorkOrderHistoryLogColumns = [
    { id: 1, field: 'logNotes', headerName: 'Log Note', width: 380 },
    { id: 2, field: 'workOrderLogDate', headerName: 'Log Date', width: 240, ...dateFormatter },
];

export const industryTypes = [
  { key: "0", value: "Select Industry Type" },
  { key: "1", value: "Agriculture, Forestry and Fisheries" },
  { key: "2", value: "Mining" },
  { key: "3", value: "Construction" },
  { key: "4", value: "Manufacturing" },
  { key: "5", value: "Wholesale Trade" },
  { key: "6", value: "Retail Trade" },
  { key: "7", value: "Transportation" },
  { key: "8", value: "Finance, Insurance and Real Estate" },
  { key: "9", value: "Services" },
  { key: "10", value: "Public Administration" }
];

export const businessTypes = {
  "Select Industry Type": ["Select Business Type"],
  "Agriculture, Forestry and Fisheries": [
    { key: "1", value: "Agricultural Production (Crops)" },
    { key: "2", value: "Agricultural Production (Livestock)" },
    { key: "3", value: "Agricultural Services" },
    { key: "4", value: "Dairy Farming" },
    { key: "5", value: "Fhishing, Hunting and Trappoing" },
    { key: "6", value: "Fishery" },
    { key: "7", value: "Forestry" },
    { key: "8", value: "Other" }
  ],
  Mining: [
    { key: "1", value: "Coal Mining" },
    { key: "2", value: "Metal Mining" },
    {
      key: "3",
      value: "Mining and Quarrying of Non-Metalic Minerals, except Fuels"
    },
    { key: "4", value: "Oil and Gas Extraction" },
    { key: "5", value: "Process Plants, Refineries and Production Plants" },
    { key: "6", value: "Other" }
  ],
  Construction: [
    {
      key: "1",
      value: "Building Construction, General Contractors and Operative Builders"
    },
    { key: "2", value: "Construction (Special Trade Contractors)" },
    {
      key: "3",
      value: "Heavy Construction other than Building Construction Contractors"
    },
    { key: "4", value: "Other" }
  ],
  Manufacturing: [
    {
      key: "1",
      value:
        "Apparel and other Finished Products made from Fabrics and similar materials"
    },
    { key: "2", value: "Chemicals and Allied Products" },
    {
      key: "3",
      value:
        "Electronic and other Electrical Equipment and Components, except Computer Equipment"
    },
    {
      key: "4",
      value:
        "Fabricated Metal Products, except Machinery and Transport Equipment"
    },
    { key: "5", value: "Food and Kindred Products" },
    { key: "6", value: "Furniture and Fixtures" },
    {
      key: "7",
      value: "Industrial and Commercial Machinery and Computer Equipment"
    },
    { key: "8", value: "Leather and Leather Products" },
    { key: "9", value: "Lumber and Wood Products, except Furniture" },
    {
      key: "10",
      value:
        "Measuring, Analyzing, and Controlling Instruments (Photographic, Medical and Optical Goods)"
    },
    { key: "11", value: "Miscellaneous Manufacturing Industries" },
    { key: "12", value: "Paper and Allied Products" },
    { key: "13", value: "Petroleum Refining and Related Industries" },
    { key: "14", value: "Primary Metal Industries" },
    { key: "15", value: "Printing, Publishing and Allied Industries" },
    { key: "16", value: "Rubber and Miscellaneous Plastic Products" },
    { key: "17", value: "Stone, Clay, Glass and Concrete Products" },
    { key: "18", value: "Tabacco Products" },
    { key: "19", value: "Textile Mill Products" },
    { key: "20", value: "Transportation Equipment" },
    { key: "21", value: "Other" }
  ],
  "Wholesale Trade": [
    { key: "19", value: "Wholesale (Trade-durable Goods)" },
    { key: "20", value: "Wholesale (Trade-non-durable Goods)" },
    { key: "21", value: "Other" }
  ],
  "Retail Trade": [
    { key: "1", value: "Apparel and Accessory Stores" },
    { key: "2", value: "Automotive Dealership" },
    {
      key: "3",
      value:
        "Building Materials, Hardware, Garden Supply, and Mobile Home Dealers"
    },
    { key: "4", value: "Food Stores" },
    { key: "5", value: "Gassoline/ Fuel Service Stations" },
    { key: "6", value: "General Merchandise Stores" },
    { key: "7", value: "Home Furniture, Furnishings, and Equipment Stores" },
    { key: "8", value: "Miscellaneous Retail" },
    { key: "9", value: "Restaurants, Bars and Fast Food Outlets" },
    { key: "10", value: "Other" }
  ],
  Transportation: [
    { key: "1", value: "Communications" },
    { key: "2", value: "Electric, Gas, and Sanitary Services" },
    {
      key: "3",
      value:
        "Local and Suburban Transit and Interurban Highway Passenger Transportation"
    },
    { key: "4", value: "Motor Freight Transportation and Warehousing" },
    { key: "5", value: "Pipelines, except Natural Gas" },
    { key: "6", value: "Railroad Transportation" },
    { key: "7", value: "Transportation by Air" },
    { key: "8", value: "Transportation Services" },
    { key: "9", value: "United States Postal Service" },
    { key: "10", value: "Water Transportation" },
    { key: "11", value: "Other" }
  ],
  "Finance, Insurance and Real Estate": [
    { key: "1", value: "Depository Institutions" },
    { key: "2", value: "Holding and other Investment Offices" },
    { key: "3", value: "Insurance Agents, Brokers and Services" },
    { key: "4", value: "Insurance Carriers" },
    { key: "5", value: "Non-depository Credit Institutions" },
    { key: "6", value: "Real Estate" },
    {
      key: "7",
      value: "Security and Commodity Brokers, Dealers, Exchanges, and Services"
    },
    { key: "8", value: "Other" }
  ],
  Services: [
    { key: "1", value: "Accounting Services" },
    { key: "2", value: "Amusement and Recreation Services" },
    { key: "3", value: "Automotive Repair, Services, and Parking" },
    { key: "4", value: "Botanical and Zoological Gardens" },
    { key: "5", value: "Business Services" },
    { key: "6", value: "Educational Services" },
    { key: "7", value: "Engineering Consulting" },
    { key: "8", value: "Health Services" },
    {
      key: "9",
      value: "Hotels, Rooming Houses, Camps and other Lodging Places"
    },
    { key: "10", value: "Legal Services" },
    { key: "11", value: "Management Consulting and Related Services" },
    { key: "12", value: "Membership Organizations" },
    { key: "13", value: "Miscellaneous Repair Services" },
    { key: "14", value: "Miscellaneous Services" },
    { key: "15", value: "Motion Pictures" },
    { key: "16", value: "Museums and Art Galleries" },
    { key: "17", value: "Personal Services" },
    { key: "18", value: "Research and Related Services" },
    { key: "19", value: "Social Services" },
    { key: "20", value: "Other" }
  ],
  "Public Administration": [
    { key: "1", value: "Administration of Economic Programs" },
    {
      key: "2",
      value: "Administration of Environmental Quality and Housing Programs"
    },
    { key: "3", value: "Administration of Human Resources Program" },
    {
      key: "4",
      value: "Exceutive, Legislative and General Government, except Finance"
    },
    { key: "5", value: "Justice, Public Order and Safety Activities" },
    { key: "6", value: "National Security and International Affairs" },
    { key: "7", value: "Nonclasssifiable Establishments" },
    { key: "8", value: "Public Finance, Taxation and Monetary Policy" },
    { key: "9", value: "Other" }
  ]
};

export const regions = [
  { key: "0", value: "Select One" },
  { key: "1", value: "Africa" },
  { key: "2", value: "Asia" },
  { key: "3", value: "Europe" },
  { key: "4", value: "North America" },
  { key: "5", value: "South America" },
  { key: "6", value: "Australia" }
];

export const group = [
  "Emergency Repair",
  "Calibration",
  "Rotable Repair",
  "Parts Required",
  "Rotable Replacement"
];


export const group1 = ["Mechanical", "Electrical"];

export const group2 = ["Extra Data"];

export const keywords = ["Breakdown"];

export const taskGroupTaskSubs = {
  Labour : "labour",
  Parts : "parts",
  SubTasks: "subtasks",
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function convertCurrency(num) {
    if (num || num === 0) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    else {
        return '';
    }
}