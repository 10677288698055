import React, { useEffect, useState } from "react";
import { AppBar, Hidden, Icon, Toolbar, Button, IconButton, Grid } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { red } from '@material-ui/core/colors';
import { FuseSearch, FuseShortcuts } from "../../../../@fuse";
import NavbarMobileToggleButton from "../../../../app/fuse-layouts/shared-components/NavbarMobileToggleButton";
import QuickPanelToggleButton from "../../../../app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton";
import UserMenu from "../../../../app/fuse-layouts/shared-components/UserMenu";
import HelpButton from "../../../../app/fuse-layouts/shared-components/HelpButton";
import MarketingButton from "../../../../app/main/components/MarketingButton";
import SpinviewButton from "../../../../app/main/components/SpinviewButton";
import logoImage from "../../../../app/main/Icons/logo.png"
//import ChatButton from "../../../../app/fuse-layouts/shared-components/ChatButton";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from '../../../store/actions';
import history from "../../../../@history";

const useStyles = makeStyles(theme => ({
  separator: {
    width: 1,
    height: 64,
    backgroundColor: theme.palette.divider
    }
}));

function ToolbarLayout1(props) {
    const dispatch = useDispatch();
    const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
    const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);
    const tenant = useSelector(({ fuse }) => fuse.tenant);
    const environment = useSelector(({ auth }) => auth.user.environment);
    const tenantLogo = useSelector(({ fuse }) => fuse.tenant.logo);
    const classes = useStyles(props);

    useEffect(() => {
        if (!tenantLogo) {
            dispatch(Actions.fetchLogo());
        }
    }, []);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar id="fuse-toolbar" className="flex relative z-10" color="default">
        <Toolbar className="p-0">
          {config.navbar.display && config.navbar.position === "left" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton className="w-64 h-64 p-0" />
              <div className={classes.separator} />
            </Hidden>
          )}
            <Grid container spacing={2} direction="row">
              <Grid item xs={4} container alignItems="center">
                <div className="flex flex-1">
                          {/*<Hidden mdDown>
                    <FuseShortcuts className="px-16" />
                  </Hidden>*/}
                              {tenantLogo && <img src={tenantLogo} alt="Logo" style={{ width: '200px', height: '45px' }} />}
                </div>
              </Grid>
              <Grid item xs={4} container alignItems="center">
                <h1 className="flex flex-1 content-center items-center justify-center">
                      <b>{tenant.tenantDetails.name}</b>
                </h1>
              </Grid>
              <Grid item xs={4}>
                <div className="flex justify-end">
                    <UserMenu />
                    <div className={classes.separator} />
                        <HelpButton />       
                        {/* <IconButton className="w-64 h-64">
                            <Icon>helpoutline</Icon>
                        </IconButton> */}
                  
                    <div className={classes.separator} />
                        {/*<SpinviewButton/>*/}
                        <MarketingButton /> 
                        {/*<FuseSearch />*/}

                        {/*<ChatButton />*/}

                    <Hidden lgUp>
                      <div className={classes.separator} />
                    </Hidden>

                    <div className={classes.separator} />

                        <QuickPanelToggleButton />
                              {/*<Button id="fuse-settings" variant="contained" onClick={props.handleOpen}>
                            <Icon>settings</Icon>
                        </Button>*/}
                                  
                </div>
              </Grid>
            </Grid>
         

            

            
              
          {config.navbar.display && config.navbar.position === "right" && (
            <Hidden lgUp>
              <NavbarMobileToggleButton />
            </Hidden>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default ToolbarLayout1;
