import React from "react";

export const GatewaysConfig = {
  settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
  },
    routes: [
        {
            path: "/IoT/Gateways/:productId",
            component: React.lazy(() => import("./GatewaysRecord"))
        },
        {
              path: "/IoT/Gateways",
          component: React.lazy(() => import("./Gateways"))
        },
  ]
};
