import React from 'react';

export const ProfilePageConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
    },
    routes  : [
        {
            path     : '/user/profile',
            component: React.lazy(() => import('./ProfilePage'))
        }
    ]
};
