import React from "react";

export const AnalyticsConfig = {
    settings: {
        layout: {
            config: {
                rightSidePanel: {
                    display: true
                }
            }
        }
    },
    routes: [
        {
            path: "/analytics/:analyticsId",
            component: React.lazy(() => import("./Analytics"))
        },
        {
            path: "/maintenance-management/kpi-dashboard/:analyticsId",
            component: React.lazy(() => import("./Analytics"))
        },
        {
            path: "/materials-management/warehouse-dashboard/:analyticsId",
            component: React.lazy(() => import("./Analytics"))
        }
    ]
};